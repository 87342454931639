import React from 'react'
import HeroBgAnimation from '../HeroBgAnimation'
import {
    HeroContainer,
    HeroBg,
    HeroLeftContainer,
    Img,
    HeroRightContainer,
    HeroInnerContainer,
    TextLoop,
    Title,
    Span,
    SubTitle,
    SocialMediaIcons,
    SocialMediaIcon,
    ResumeButton
} from './HeroStyle'
import HeroImg from '../../images/frunolu-img.png'
import Typewriter from 'typewriter-effect';
import {Bio} from '../../data/constants';
import styled from 'styled-components';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import InstagramIcon from '@mui/icons-material/Instagram';


const HeroSection = () => {
    return (
        <div id="about">
            <HeroContainer>
                <HeroBg>
                    <HeroBgAnimation/>
                </HeroBg>
                <HeroInnerContainer>
                    <HeroLeftContainer id="Left">
                        <Title>Hi, I am <br/> {Bio.name}</Title>
                        <TextLoop>
                            I am a
                            <Span>
                                <Typewriter
                                    options={{
                                        strings: Bio.roles,
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </Span>
                        </TextLoop>
                        <SubTitle>{Bio.description}</SubTitle>
                        <SubTitle> <SocialMediaIcon href={Bio.contactemail}
                                                    target="display">frunolu@frunolu.eu
                        </SocialMediaIcon>
                            <SocialMediaIcon href={Bio.contactphone}
                                             target="display">+420 606 218 592
                            </SocialMediaIcon>
                            <SocialMediaIcon href={Bio.contactemailgmail}
                                                    target="display">fruno.lu@gmail.com
                        </SocialMediaIcon>

                            <SocialMediaIcon href={Bio.contactphoneSK}
                                             target="display">+421 905 618 081
                            </SocialMediaIcon>
                            <SocialMediaIcons style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <SocialMediaIcon href={Bio.linkedin} target="display"><LinkedInIcon/></SocialMediaIcon>

                                <SocialMediaIcon href={Bio.whatsapp} target="display"><WhatsAppIcon/></SocialMediaIcon>
                                <SocialMediaIcon href={Bio.twitter} target="display"><XIcon/></SocialMediaIcon>

                                <SocialMediaIcon href={Bio.insta} target="display"><InstagramIcon/></SocialMediaIcon>
                                <SocialMediaIcon href={Bio.facebook} target="display"><FacebookIcon/></SocialMediaIcon>
                            </SocialMediaIcons>


                        </SubTitle>

                        {/*<ResumeButton href={Bio.resume} target='display'>Check Resume</ResumeButton>*/}
                    </HeroLeftContainer>

                    <HeroRightContainer id="Right">

                        <Img src={HeroImg} alt="hero-image"/>
                    </HeroRightContainer>
                </HeroInnerContainer>

            </HeroContainer>
        </div>
    )
}

export default HeroSection